@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: #333;
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 13px;
}

@media only screen and (max-width: 640px) {
  body {
    font-size: 12px;
  }
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  #contents_wrap {
    margin: 50px 0;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 768px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  header#global_header {
    padding-top: 48px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  background: white !important;
  border-bottom: 1px solid #ddd;
  z-index: 11;
  top: 0;
  width: 100%;
}

.fixed #siteID2 {
  display: block;
  margin-left: 10%;
}

.fixed #siteID2 img {
  width: 100% !important;
  max-width: calc( 425px / 2);
}

.fixed .navwrap {
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fixed #nav_global {
  margin: 0;
  margin-right: 10%;
}

#siteID2 {
  display: none;
}

#header {
  position: relative;
  width: 100%;
}

#header #siteID {
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 1000px) {
  #header #siteID {
    width: 20%;
  }
}

#header #siteID a {
  opacity: 1 !important;
}

#header #siteID img {
  width: 100% !important;
  max-width: calc( 558px / 2);
}

#header .hed_info {
  position: fixed;
  z-index: 5;
  right: 5%;
  bottom: 5%;
  display: flex;
}

#header .hed_info .line {
  min-width: 260px;
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  #header .hed_info .line {
    display: none;
  }
}

#header .hed_info .line a {
  display: block;
  background: #00c300;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  padding: 15px 70px;
  font-size: 25px;
  font-size: 1.5625vw;
}

@media only screen and (min-width: 1600px) {
  #header .hed_info .line a {
    font-size: 25px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #header .hed_info .line a {
    padding: 10px 50px;
  }
}

#header .hed_info .line a i {
  margin-right: 7px;
}

#header .hed_info .shop {
  min-width: 260px;
}

#header .hed_info .shop a {
  display: block;
  background: #d6c678;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  padding: 15px 20px;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  #header .hed_info .shop a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  #header .hed_info .shop a {
    padding: 15px 30px;
    font-size: 16px;
  }
  #header .hed_info .shop a i {
    color: #000;
  }
}

#header .hed_info .shop a i {
  margin-right: 7px;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 768px) {
  .pcnone {
    display: block;
  }
}

#nav_global {
  z-index: 10;
  position: relative;
  margin: 20px auto;
  max-width: 1200px;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
  flex-grow: 1;
}

#nav_global ul li a {
  font-size: 16px;
  display: block;
  font-weight: 200;
  color: #b2b2b2 !important;
  padding: 10px 15px;
}

#nav_global ul li a:hover {
  transition: 0.5s;
}

#nav_global ul li:last-child a {
  border: 0;
}

#nav_global ul li ul {
  position: absolute;
  width: 260px;
  top: 44px;
  right: -50px;
  margin: auto;
  display: flex;
  flex-flow: column;
}

@media only screen and (max-width: 768px) {
  #nav_global ul li ul {
    right: 0;
  }
}

#nav_global ul li ul li {
  width: 100%;
}

#nav_global ul li ul li a {
  width: 100%;
  font-size: 13px;
  color: #fff !important;
  display: block;
  padding: 15px;
}

#nav_global ul li ul li a:hover {
  transition: 0.5s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 768px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 5px;
    top: 5px;
    color: #b5a766;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  #hednav {
    justify-content: center;
    height: 48px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    margin: 0;
    background: #fff;
    border-bottom: 1px solid #ddd;
  }
  #hednav .wrap2 {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap2 .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap2 .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap2 .navwrap #hed_info .store1 p {
    background: #cdbe75;
  }
  #hednav .wrap2 .navwrap #hed_info .store2 p {
    background: #c3b56e;
  }
  #hednav .wrap2 .navwrap #hed_info .store3 p {
    background: #b5a766;
  }
  #hednav .wrap2 .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap2 .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap2 .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap2 .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap2 .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap2 .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap2 .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap2 .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    top: 35%;
    z-index: 1;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #b5a766;
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f004";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 16px;
    content: "\f290";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 12px;
    content: "\f553";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 10px;
    content: "\f4a1";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(5) a::before {
    content: "\f733";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 13px;
    content: "\f2bd";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(7) a::before {
    left: 12px;
    content: "\f4be";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(8) a::before {
    content: "\f236";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap2 {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap2 .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap2 .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  width: 100%;
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
}

@media only screen and (max-width: 768px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 4em;
  font-weight: 500;
  z-index: 1;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

@media only screen and (max-width: 768px) {
  #local-keyvisual h1 {
    font-size: 2em;
  }
}

#local-keyvisual h1 span {
  padding: 75px 0;
  display: block;
}

@media only screen and (max-width: 768px) {
  #local-keyvisual h1 span {
    padding: 40px 0;
  }
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  margin: 20px 0 100px;
}

@media only screen and (max-width: 768px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  min-width: 100%;
}

#copy {
  display: block;
}

#copy a {
  text-decoration: none;
  color: #333;
}

#copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  #copy a {
    line-height: 60px;
  }
}

.cont_1 {
  line-height: 3em;
  padding: 0 10px;
}

@media only screen and (max-width: 768px) {
  .cont_1 {
    padding: 0;
    line-height: 1.7em;
    font-size: 10px;
  }
}

.cont_2 {
  background: #f2efe0;
  padding: 100px 0;
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 768px) {
  .cont_2 {
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    font-size: 11px;
  }
}

.cont_2 .wrap2 {
  margin: 0 auto;
  max-width: 1200px;
}

.cont_2 .wrap2 li {
  padding: 0 10px;
}

.cont_2 .itemlist {
  margin: 0 auto;
  max-width: 1200px;
}

.cont_2 .itemlist li {
  padding: 0 10px;
}

.cont_2 .itemlist li img {
  width: 100%;
  margin-bottom: 20px;
  height: 20vw !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 1200px) {
  .cont_2 .itemlist li img {
    height: 30vw !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .cont_2 .itemlist li img {
    height: 60vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 .itemlist li img {
    height: 75vw !important;
  }
}

.cont_2 .itemlist li .txt span, .cont_2 .itemlist li .txt a {
  display: none;
}

.cont_3 {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 1200px) {
  .cont_3 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_3 {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 {
    display: block;
  }
}

.cont_3 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3 .flxL {
    width: 100%;
  }
}

.cont_3 .flxL .scl {
  height: 620px;
  overflow-y: scroll;
  padding: 10px;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .cont_3 .flxL .scl {
    height: 490px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .flxL .scl {
    height: 350px;
    margin-bottom: 50px;
  }
}

.cont_3 .flxL .scl .box {
  margin-bottom: 20px;
}

.cont_3 .flxL .scl .box article h3 {
  padding-left: 20px;
  width: 85%;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .flxL .scl .box article h3 {
    width: 80%;
  }
}

.cont_3 .flxL .scl .box article div {
  padding-left: 20px;
  width: 85%;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .flxL .scl .box article div {
    width: 80%;
  }
}

.cont_3 .flxL .scl .box img {
  float: left;
  height: 150px !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
  width: 15% !important;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .flxL .scl .box img {
    width: 18% !important;
    height: 15vw !important;
  }
}

.cont_3 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3 .flxR {
    width: 100%;
  }
}

.cont_3 .flxR .btn {
  margin: 20px auto 0;
  width: 100% !important;
}

/* box */
.sns .sns_list {
  border: 0 !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -1.23457% !important;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sns .sns_list {
    margin-left: -1.23457% !important;
  }
}

.sns .sns_list > div {
  border-bottom: 0 !important;
  width: 32.09877% !important;
  margin-left: 1.23457% !important;
  margin-bottom: 1.23457% !important;
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .sns .sns_list > div {
    width: 32.09877% !important;
    margin-left: 1.23457% !important;
    display: block;
  }
}

.sns .sns_list > div img {
  width: 100% !important;
  height: 8vw !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  .sns .sns_list > div img {
    height: 15vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .sns .sns_list > div img {
    height: 17vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .sns .sns_list > div img {
    height: 27vw !important;
  }
}

.sns .sns_list .sns_photo {
  padding: 0 !important;
  width: 100% !important;
}

.sns .sns_list .sns_text {
  display: none;
}

.cont_4 {
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_4 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_4 {
    padding: 0;
  }
}

.cont_4 ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.cont_4 ul li {
  width: 48%;
  margin-bottom: 30px;
}

.cont_5 {
  background: #f2efe0;
  padding: 100px 0;
}

@media only screen and (max-width: 768px) {
  .cont_5 {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 768px) {
  .cont_5 {
    padding: 20px;
  }
}

.cont_5 .wrap2 {
  margin: 0 auto;
  max-width: 1200px;
}

.cont_5 .wrap2 li {
  padding: 0 10px;
}

@media only screen and (max-width: 1200px) {
  .brand {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .brand {
    padding: 0;
  }
}

.brand a {
  display: block;
}

.brand a td {
  border: 0;
}

@media only screen and (max-width: 768px) {
  .brand a td {
    padding: 0 !important;
  }
}

.btn {
  justify-content: space-between !important;
  margin: 50px auto 0;
  width: 20%;
}

@media only screen and (max-width: 1200px) {
  .btn {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .btn {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .btn {
    display: block;
    padding: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .btn {
    margin: 30px auto 0;
  }
}

.btn a {
  display: block;
  background: #000;
  padding: 20px 10px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  transition: 1s;
}

.btn a:hover {
  opacity: 1;
  background: #313131;
}

.btn2 {
  justify-content: space-between !important;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .btn2 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 640px) {
  .btn2 {
    padding: 0;
    display: block !important;
  }
}

.btn2 .box {
  width: 30%;
  margin: 0 10px;
}

@media only screen and (max-width: 640px) {
  .btn2 .box {
    margin: 0 0 20px 0;
    width: 100%;
  }
}

.btn2 a {
  text-align: center;
  display: block;
  background: #000;
  padding: 20px 10px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  transition: 1s;
}

.btn2 a:hover {
  opacity: 1;
  background: #313131;
}

.btn3 {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin: 50px auto 0;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .btn3 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 640px) {
  .btn3 {
    margin: 30px auto 0;
  }
}

.btn3 dl {
  width: 23%;
}

@media only screen and (max-width: 640px) {
  .btn3 dl {
    width: 48%;
    margin-bottom: 15px;
  }
}

.btn3 dl dt {
  display: none !important;
}

.btn3 dl dd {
  padding: 0 !important;
  width: 100% !important;
  text-align: center;
  border: 0 !important;
}

.btn3 a {
  display: block;
  background: #000;
  padding: 20px 10px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  transition: 1s;
}

.btn3 a:hover {
  opacity: 1;
  background: #313131;
}

.cont_6 {
  justify-content: space-between !important;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .cont_6 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_6 {
    padding: 0;
    display: block !important;
  }
  .cont_6 .box {
    width: 100% !important;
    margin-bottom: 20px;
  }
  .cont_6 img {
    width: 100% !important;
  }
}

.cont_6 .box {
  width: 32%;
}

.cont_7 {
  position: relative;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .cont_7 {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .cont_7 {
    display: block;
    padding: 0;
  }
}

.cont_7 .flxL {
  width: 60%;
  background: #f2f2f2;
  padding: 13% 20% 13% 10%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .cont_7 .flxL {
    width: 100%;
    padding: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_7 .flxL {
    padding: 20px;
    margin-bottom: 20px;
  }
}

.cont_7 .flxL dl {
  align-items: flex-start;
}

.cont_7 .flxL dl dt {
  border: 0 !important;
}

.cont_7 .flxL dl dd {
  border: 0 !important;
  background: none !important;
}

.cont_7 .flxL dl a {
  color: #000;
}

.cont_7 .flxL dl a i {
  font-size: 30px;
  margin-right: 10px;
}

.cont_7 .flxR {
  width: 50%;
  right: 0;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .cont_7 .flxR {
    width: 100%;
    position: static;
  }
}

.yotube2 {
  background: #000;
  padding: 50px 0;
}

@media only screen and (max-width: 640px) {
  .yotube2 {
    padding: 0;
    background: none;
  }
}

.yotube2 .midashi h2 {
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .yotube2 .midashi h2 {
    color: #000;
  }
}

.youtube {
  position: relative;
}

.youtube .slick-prev {
  left: 20%;
  z-index: 1;
  width: 60px;
  height: 60px;
}

@media only screen and (max-width: 640px) {
  .youtube .slick-prev {
    left: 1%;
    width: 30px;
    height: 30px;
  }
}

.youtube .slick-next {
  right: 20%;
  z-index: 1;
  width: 60px;
  height: 60px;
}

@media only screen and (max-width: 640px) {
  .youtube .slick-next {
    right: 1%;
    width: 30px;
    height: 30px;
  }
}

.youtube .slick-prev:before, .youtube .slick-next:before {
  font-size: 60px;
  color: #000;
}

@media only screen and (max-width: 640px) {
  .youtube .slick-prev:before, .youtube .slick-next:before {
    font-size: 30px;
  }
}

.youtube .box {
  height: 550px;
}

@media only screen and (max-width: 640px) {
  .youtube .box {
    height: 300px !important;
  }
}

.youtube .slick-slide {
  display: block;
  background: #000;
}

.youtube .slick-slide iframe {
  opacity: 0.5;
  height: 550px !important;
}

@media only screen and (max-width: 640px) {
  .youtube .slick-slide iframe {
    height: 300px !important;
  }
}

.youtube .slick-slide img {
  opacity: 0.5;
  height: 550px !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

@media only screen and (max-width: 640px) {
  .youtube .slick-slide img {
    height: 300px !important;
  }
}

.youtube .slick-active iframe, .youtube .slick-active img {
  opacity: 1;
}

.bland {
  max-width: 1200px;
}

@media only screen and (max-width: 640px) {
  .gmap iframe {
    height: 300px !important;
  }
}

/* title */
.midashi {
  padding: 0 10px;
  margin: 0 0 40px 0;
}

@media only screen and (max-width: 768px) {
  .midashi {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  .midashi {
    margin-bottom: 20px;
  }
}

.midashi h2 {
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1em;
  font-size: 40px;
  font-size: 2.5vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 40px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    line-height: 1.7em;
    font-size: 20px;
  }
}

.midashi h3 {
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1em;
  margin-bottom: 30px;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h3 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h3 {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.midashi2 h2 {
  line-height: 1.7em;
  color: #343849;
  font-weight: 400;
  font-size: 35px;
  font-size: 2.1875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 35px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

.midashi3 h2 {
  line-height: 1.7em;
  padding: 0.5em 1em;
  color: #494949;
  background: #f4f4f4;
  border-left: solid 5px #7db4e6;
  border-bottom: solid 3px #d7d7d7;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi3 h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi3 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi3 h2 {
    font-size: 20px;
  }
}

.midashi4 h2 {
  line-height: 1.7em;
  padding: 0.5em 1em;
  color: #fff;
  background: #333;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi4 h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .midashi4 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi4 h2 {
    font-size: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 768px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #f2f2e9 no-repeat;
  background-size: cover;
  z-index: -1;
}

.items {
  background: #f1f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22);
  padding: 2em;
}

.items2 h3 {
  font-size: 1.2em;
  background: #5fc2f5;
  padding: 1em;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  letter-spacing: 0.05em;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.cont_8 {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .cont_8 {
    display: block;
  }
}

.cont_8:nth-child(even) {
  flex-direction: row-reverse;
}

.cont_8 .flxL {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .cont_8 .flxL {
    width: 100%;
  }
}

.cont_8 .flxR {
  width: 45%;
}

@media only screen and (max-width: 640px) {
  .cont_8 .flxR {
    width: 100%;
  }
}

.cont_8 .flxR img {
  width: 100% !important;
}

.comp dl {
  border-bottom: 1px solid #ccc;
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl dd {
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl a {
  color: #b2b2b2;
}

/* box */
.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -1.63934% !important;
}

@media only screen and (max-width: 768px) {
  .item {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .item .wrap2 {
    justify-content: space-between;
  }
}

.item .box {
  width: 23.36066% !important;
  margin-left: 1.63934% !important;
  margin-bottom: 1.63934% !important;
}

@media only screen and (max-width: 768px) {
  .item .box {
    width: 48% !important;
    margin-left: 0 !important;
    margin-bottom: 30px !important;
    display: block;
  }
}

.item .box img {
  width: 100% !important;
}

.item .box span {
  display: inline-block;
  width: 25%;
  color: #fff;
  background: #ccc;
  padding: 5px;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .item .box span {
    width: 50%;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

.comp2 dl dd {
  padding: 15px !important;
}

.comp2 dl a {
  color: #b2b2b2;
}

.media .box {
  padding: 30px 0;
  border-top: 1px solid #ddd;
}

.media .box article h3 {
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0.2em;
  padding-left: 20px;
  width: 70%;
  float: left;
  margin-bottom: 15px;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .media .box article h3 {
    font-size: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .media .box article h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .media .box article h3 {
    line-height: 1.7em;
    font-size: 16px;
  }
}

.media .box article div {
  padding-left: 20px;
  width: 70%;
  float: left;
}

.media .box img {
  float: left;
  height: 20vw !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
  width: 30% !important;
}

@media only screen and (max-width: 1000px) {
  .media .box img {
    height: 30vw !important;
  }
}

.media .box a {
  text-align: center;
  display: block;
  background: #000;
  padding: 20px 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  transition: 1s;
  width: 50%;
}

@media only screen and (max-width: 1200px) {
  .media .box a {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .media .box a {
    width: 50%;
    padding: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .media .box a {
    width: 100%;
  }
}

.media .box a:hover {
  opacity: 1;
  background: #313131;
}

.media:last-child {
  border-bottom: 1px solid #ddd;
}

.bland1 td {
  border: 0 !important;
  font-size: 16px;
}
